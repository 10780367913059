<template>
  <div id="production-cad" v-if="fontFile">
    <div id="view-sub-nav">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" depressed outlined color="primary" @click="generate">Regenerate</v-btn>
      <v-btn
        class="ml-2"
        depressed
        outlined
        color="primary"
        @click="
          () => {
            if (urtilData.length > 0) {
              dxfDialog = true
            } else {
              dxf()
            }
          }
        "
        >Download Production Files</v-btn
      >
    </div>

    <v-card class="material mb-4" v-for="(material, key) in itemsByMaterial" :key="key">
      <v-card-title class="primary white--text">
        <h3>
          {{ materialLookup[key].thickness }}mm {{ materialLookup[key].name
          }}{{ materialLookup[key].sides == 1 ? ' [Single-Sided]' : '' }}
        </h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="materialLookup[key].thickness"
          label="Exact Material Thickness"
          dark
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-' + [key]" v-html="svgs[key]"></div>
      </v-card-text>
    </v-card>
    <v-card
      class="material mb-4"
      v-if="foundJHandle"
      v-for="(material, key) in jHandlesByMaterial"
      :key="key + '_jHand'"
    >
      <v-card-title class="primary white--text">
        <h3>{{ materialLookup[key].thickness }}mm J-Handles {{ materialLookup[key].name }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="materialLookup[key].thickness"
          label="Exact Material Thickness"
          dark
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-jHand-' + [key]" v-html="svgs[key + '-jHand']"></div>
      </v-card-text>
    </v-card>
    <v-card
      class="material mb-4"
      v-if="foundSRGHandle"
      v-for="(material, key) in srgHandlesByMaterial"
      :key="key + '_srg'"
    >
      <v-card-title class="primary white--text">
        <h3>{{ materialLookup[key].thickness }}mm SRG-Handles {{ materialLookup[key].name }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="materialLookup[key].thickness"
          label="Exact Material Thickness"
          dark
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-srg-' + [key]" v-html="svgs[key + '-srg']"></div>
      </v-card-text>
    </v-card>
    <v-card
      class="material mb-4"
      v-if="foundSRCHandle"
      v-for="(material, key) in srcHandlesByMaterial"
      :key="key + '_src'"
    >
      <v-card-title class="primary white--text">
        <h3>{{ materialLookup[key].thickness }}mm SRC-Handles {{ materialLookup[key].name }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="materialLookup[key].thickness"
          label="Exact Material Thickness"
          dark
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-src-' + [key]" v-html="svgs[key + '-src']"></div>
      </v-card-text>
    </v-card>

    <v-card
      class="material mb-4"
      v-if="foundHalfQuartArc"
      v-for="(material, key) in halfQuartArcByMaterial"
      :key="key + '_srhc'"
    >
      <v-card-title class="primary white--text">
        <h3>
          {{ materialLookup[key].thickness }}mm
          {{ materialLookup[key].name }}
          Semi-Recessed Half-Quarter Arc Handles
        </h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="materialLookup[key].thickness"
          label="Exact Material Thickness"
          dark
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-hqa-' + [key]" v-html="svgs[key + '-hqa']"></div>
      </v-card-text>
    </v-card>

    <v-card class="material mb-4" v-if="quote.region === 'us' && version.spacerPanels.length > 0">
      <v-card-title class="primary white--text">
        <h3>Spacer Panels</h3>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-spacer-panels'" v-html="svgs['spacer-panels']"></div>
      </v-card-text>
    </v-card>

    <v-card class="material mb-4" v-if="requiresWorktopCad">
      <v-card-title class="primary white--text">
        <h3>Worktops</h3>
      </v-card-title>
      <v-card-text>
        <div class="svg" :id="'svg-worktops'" v-html="svgs['worktops']"></div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dxfDialog" width="500">
      <v-card>
        <v-card-title class="warning white--text title">Material Thickness Required!</v-card-title>
        <v-card-text class="pt-2">
          This CAD contains
          {{ urtilData.length == 1 ? 'an Ürtil' : 'Ürtils' }} you must input the exact material thickness and then
          regenerate the CAD before downloading.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dxfDialog = false">Cancel</v-btn>
          <v-btn color="warning" depressed dark @click="dxf">Download</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let opentype = require('opentype.js')

import makerjs from 'makerjs'
import svgPanZoom from 'svg-pan-zoom'
import jszip from 'jszip'
import filesaver from 'file-saver'
import { layerOptions, layerNames } from '@/js/cad/CadLayerNaming.js'
import cadUtils from '@/js/cad/CadUtils'
import Front from '@/js/cad/production/Front'
import CoverPanel from '@/js/cad/production/CoverPanel'
import SpacerPanel from '@/js/cad/production/SpacerPanel'
import Worktop from '@/js/cad/production/Worktop'
import Urtil from '@/js/cad/production/products/urtil/Urtil'
import JHandle from '@/js/cad/production/specials/JHandle'
import SRGHandle from '@/js/cad/production/specials/SRGHandle'
import SRCHandle from '@/js/cad/production/specials/SRCHandle'
import HalfQuartArcHandle from '@/js/cad/production/specials/HalfQuartArcHandle'
import Pantry from '@/js/cad/production/products/Pantry'

import OLFSimpleSans from '@/fonts/OLFSimpleSans-Regular.ttf'

export default {
  name: 'MegaCad',
  props: ['quote', 'version', 'customerEmail'],
  data() {
    return {
      loadingUI: null,
      reloading: false,
      spacerPanelDepth: 75,
      makerjs: makerjs,
      svg: null,
      handleLookup: null,
      materialLookup: null,
      itemsByMaterial: null,
      handlesByMaterial: null,
      jHandlesByMaterial: null,
      srgHandlesByMaterial: null,
      halfQuartArcByMaterial: null,
      frontsData: null,
      coverPanelData: null,
      urtilData: null,
      svgs: {},
      frontTrimAmmount: 3,
      verticalItemTypeSpacing: 200,
      models: [],
      fontFile: null,
      dxfDialog: false,
      handleSpacing: 9,
      foundJHandle: false,
      foundSRGHandle: false,
      foundSRCHandle: false,
      foundHalfQuartArc: false
    }
  },
  computed: {
    frontSpacing() {
      return this.quote.region === 'us' ? 11 : 13
    },
    requiresWorktopCad() {
      let required
      if (this.quote.region === 'us' && this.version.worktops.length > 0) {
        required = true
      } else if (this.quote.region === 'uk' && this.version.worktops.length > 0) {
        this.version.worktops.forEach(worktop => {
          if (worktop.sections.find(section => section.dogleg)) {
            required = true
          }
        })
      }
      return required
    }
  },
  created() {
    opentype.load(OLFSimpleSans, (err, font) => {
      this.fontFile = font
      this.materialLookup = cadUtils.constructMaterialLookup(this.version)
      this.handleLookup = cadUtils.constructHandleLookup(this.version, this.materialLookup)
      this.frontsData = cadUtils.constructFrontsArray(this.version, this.materialLookup, this.handleLookup)
      this.coverPanelData = cadUtils.constructCoverPanelArray(this.version, this.materialLookup)
      this.urtilData = cadUtils.constructUrtilArray(this.version, this.materialLookup)
      this.pantryData = cadUtils.constructPantryArray(this.version, this.materialLookup)
      this.storeItemsByMaterial()
      this.storeHandlesByMaterial()
      this.sortJHandlesByMaterial()
      this.sortSRGHandlesByMaterial()
      this.sortSRCHandlesByMaterial()
      this.sortHalfQuartArcByMaterial()
      this.generate()
    })
  },
  methods: {
    storeItemsByMaterial() {
      let a = {}

      // loop through the fronts
      for (const f of this.frontsData) {
        if (!a[f.material.id]) a[f.material.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[f.material.id].fronts.push(f)
      }

      // loop through the cover panels
      for (const cp of this.coverPanelData) {
        if (!a[cp.material.id]) a[cp.material.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[cp.material.id].coverPanels.push(cp)
      }

      // Loop through the products
      // Urtil
      for (let i = 0; i < this.urtilData.length; i++) {
        let urtil = this.urtilData[i]
        // for (const urtil of this.urtilData) {
        // Add carcass
        if (!a[urtil.carcass.id]) a[urtil.carcass.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[urtil.carcass.id].urtils.push(urtil)

        // Add back
        if (!a[urtil.back.id]) a[urtil.back.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[urtil.back.id].urtils.push(urtil)

        // Add door1
        if (urtil.door1) {
          if (!a[urtil.door1.id]) a[urtil.door1.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
          a[urtil.door1.id].urtils.push(urtil)
        }

        // Add door2
        if (urtil.door2) {
          if (!a[urtil.door2.id]) a[urtil.door2.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
          a[urtil.door2.id].urtils.push(urtil)
        }
      }

      // Pantry
      for (let i = 0; i < this.pantryData.length; i++) {
        let pantry = this.pantryData[i]
        // Add carcass
        if (!a[pantry.carcass.id]) a[pantry.carcass.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[pantry.carcass.id].pantries.push(pantry)

        // Add back
        if (!a[pantry.back.id]) a[pantry.back.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
        a[pantry.back.id].pantries.push(pantry)

        // Add base
        if (pantry.base) {
          if (!a[pantry.base.id]) a[pantry.base.id] = { fronts: [], coverPanels: [], urtils: [], pantries: [] }
          a[pantry.base.id].pantries.push(pantry)
        }
      }

      this.itemsByMaterial = a
    },

    storeHandlesByMaterial() {
      let h = {}
      for (const f of this.frontsData) {
        if (f.handle.insertMaterial) {
          if (!h[f.handle.insertMaterial.id]) h[f.handle.insertMaterial.id] = { handles: [] }
          h[f.handle.insertMaterial.id].handles.push(f)
        }
      }
      this.handlesByMaterial = h
    },

    sortJHandlesByMaterial() {
      let j = {}
      for (const material in this.handlesByMaterial) {
        let foundJHandles = this.handlesByMaterial[material].handles.filter(handle => {
          return handle.handle.value == 'j'
        })
        if (!j[material] && foundJHandles.length > 0) {
          j[material] = { handles: [] }
          j[material].handles = foundJHandles
        }
      }
      this.jHandlesByMaterial = j
    },

    sortSRGHandlesByMaterial() {
      let s = {}
      for (const material in this.handlesByMaterial) {
        let foundSRGHandles = this.handlesByMaterial[material].handles.filter(handle => {
          return (
            (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handle.handle.value) &&
              !handle.handle.insertMaterial.hasGrain) ||
            (['midSemiGrab', 'lrgSemiGrab'].includes(handle.handle.value) && handle.handle.insertMaterial.hasGrain) ||
            (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handle.handle.value) && handle.joiningDouble)
          )
        })
        if (!s[material] && foundSRGHandles.length > 0) {
          s[material] = { handles: [] }
          s[material].handles = foundSRGHandles
        }
      }
      this.srgHandlesByMaterial = s
    },

    sortSRCHandlesByMaterial() {
      let c = {}
      for (const material in this.handlesByMaterial) {
        let foundSRCHandles = this.handlesByMaterial[material].handles.filter(handle => {
          return handle.handle.value === 'semiCir' && !handle.handle.insertMaterial.hasGrain
        })
        if (!c[material] && foundSRCHandles.length > 0) {
          c[material] = { handles: [] }
          c[material].handles = foundSRCHandles
        }
      }
      this.srcHandlesByMaterial = c
    },

    sortHalfQuartArcByMaterial() {
      let hqa = {}
      for (const material in this.handlesByMaterial) {
        let foundHalfQuartArc = this.handlesByMaterial[material].handles.filter(hqa => {
          return ['halfArc', 'lrgHalfArc', 'quartArc', 'lrgQuartArc'].includes(hqa.handle.value)
        })
        if (!hqa[material] && foundHalfQuartArc.length > 0) {
          hqa[material] = { handles: [] }
          hqa[material].handles = foundHalfQuartArc
        }
      }
      this.halfQuartArcByMaterial = hqa
    },

    generate() {
      let materialKeys = Object.keys(this.itemsByMaterial)

      this.models = []
      this.svgs = {}

      for (let i = 0; i < materialKeys.length; i++) {
        const notes = new makerjs.models.Rectangle(0, 0)
        notes.layer = layerNames.notes.label
        const grouping = new makerjs.models.Rectangle(0, 0)
        grouping.layer = layerNames.grouping.label

        // get the items for current material
        let items = this.itemsByMaterial[materialKeys[i]]

        // create a model for this material and store in array
        let model = {
          models: {
            fronts: { models: {} },
            coverPanels: { models: {} },
            urtils: { models: {} },
            pantries: { models: {} },
            notes: notes,
            grouping: grouping
          }
        }

        // DONT NEED EXTRA PLINTHS
        // if (this.quote.region === 'uk') {
        //   model.models.plinthCut = new makerjs.models.Rectangle(2440, 75)
        //   makerjs.model.move(model.models.plinthCut, [0, 5000])
        //   model.models.plinthCut.layer = layerNames.plinthCut.label
        // }

        this.generateFronts(
          items.fronts,
          model.models.fronts,
          this.materialLookup[materialKeys[i]].hasGrain,
          this.materialLookup[materialKeys[i]].sides
        )
        this.generateCoverPanels(
          items.coverPanels,
          model.models.coverPanels,
          this.materialLookup[materialKeys[i]].hasGrain,
          this.materialLookup[materialKeys[i]].sides
        )

        this.generateUrtils(items.urtils, model.models.urtils, materialKeys[i])
        this.generatePantries(items.pantries, model.models.pantries, materialKeys[i])

        model = makerjs.model.mirror(model, true, false)
        this.svgs[materialKeys[i]] = makerjs.exporter.toSVG(model)
        this.models.push({
          materialID: materialKeys[i],
          data: model
        })
      }

      // get the j-handles for current material
      let jHandleMaterialKeys = Object.keys(this.jHandlesByMaterial)
      jHandleMaterialKeys.forEach(key => {
        let jHandles = this.jHandlesByMaterial[key].handles

        if (jHandles.length > 0) {
          this.foundJHandle = true

          this.generateJHandles(jHandles)
        }
      })

      // get the srg handles for current material
      let srgHandleMaterialKeys = Object.keys(this.srgHandlesByMaterial)
      srgHandleMaterialKeys.forEach(key => {
        let srgHandles = this.srgHandlesByMaterial[key].handles

        if (srgHandles.length > 0) {
          this.foundSRGHandle = true

          this.generateSRGHandles(srgHandles)
        }
      })

      // get the src handles for current material
      let srcHandleMaterialKeys = Object.keys(this.srcHandlesByMaterial)
      srcHandleMaterialKeys.forEach(key => {
        let srcHandles = this.srcHandlesByMaterial[key].handles

        if (srcHandles.length > 0) {
          this.foundSRCHandle = true

          this.generateSRCHandles(srcHandles, key)
        }
      })

      // looking for HalfQuartArcs
      let halfQuartArcMaterialKeys = Object.keys(this.halfQuartArcByMaterial)
      halfQuartArcMaterialKeys.forEach(key => {
        let halfQuartArc = this.halfQuartArcByMaterial[key].handles

        if (halfQuartArc.length > 0) {
          this.foundHalfQuartArc = true

          this.generateHalfQuartArc(halfQuartArc, key)
        }
      })

      if (this.quote.region === 'us' && this.version.spacerPanels.length > 0) {
        this.generateSpacerPanels()
      }

      if (this.version.worktops.length > 0) {
        this.generateWorktops()
      }

      setTimeout(() => {
        let svgs = document.getElementsByClassName('svg')
        for (let i = 0; i < svgs.length; i++) {
          this.panZoom = svgPanZoom(svgs[i].firstChild, {
            minZoom: 0.1,
            maxZoom: 50,
            zoomScaleSensitivity: 0.3,
            zoomEnabled: true,
            fit: true,
            center: true
          })
          this.panZoom.zoomBy(0.8)
        }
      }, 1)
    },

    generateSpacerPanels() {
      let xPos = 0
      let spacerCount = 0
      let spacerPanelWidth = 102 // 4 inches as for US only
      let model = { models: {} }

      for (const spacer of this.version.spacerPanels) {
        for (let i = 0; i < spacer.qty; i++) {
          model.models['spacer-' + spacerCount] = new SpacerPanel(spacer, spacerPanelWidth)
          makerjs.model.move(model.models['spacer-' + spacerCount], [xPos, 0])
          xPos += spacerPanelWidth + this.frontSpacing
          spacerCount++

          model.models['spacer-' + spacerCount] = new SpacerPanel(spacer, spacerPanelWidth)
          makerjs.model.move(model.models['spacer-' + spacerCount], [xPos, 0])
          xPos += spacerPanelWidth + this.frontSpacing
          spacerCount++
        }
      }

      this.models.push({
        name: 'spacer-panels.dxf',
        data: model
      })

      this.svgs['spacer-panels'] = makerjs.exporter.toSVG(model)
    },

    generateWorktops() {
      let xPos = 0
      let worktopCad = false
      let worktopCount = 0
      let model = { models: {} }

      for (const worktop of this.version.worktops) {
        let doglegSections = worktop.sections.filter(section => section.dogleg)

        if (this.quote.region == 'uk' && doglegSections.length > 0) {
          // only dogleg sections for UK
          worktopCad = true
          model.models['worktop-' + worktopCount] = new Worktop(doglegSections)
          makerjs.model.move(model.models['worktop-' + worktopCount], [xPos, 0])
        } else if (this.quote.region == 'us') {
          // all sections for US
          worktopCad = true
          model.models['worktop-' + worktopCount] = new Worktop(worktop.sections)
          makerjs.model.move(model.models['worktop-' + worktopCount], [xPos, 0])
        }

        xPos += 4000
        worktopCount++
      }

      if (worktopCad) {
        this.models.push({
          name: 'worktops.dxf',
          data: model
        })

        this.svgs['worktops'] = makerjs.exporter.toSVG(model)
      }
    },
    generateFronts(items, model, materialHasGrain, materialSided) {
      let xPos = 0
      let yPos = 0
      let previousItem = null
      let previousFront = null

      for (const item of items) {
        model.models[item.uid] = { models: {} }
        const qty = ['2part', 'cnr'].includes(item.special) ? 1 : item.qty

        let innerY = 0
        let front

        // create all fronts for this item
        for (let i = 0; i < qty; i++) {
          // generate label for engraving
          let label = item.itemNum.toString()
          if (qty > 1) {
            label += String.fromCharCode(97 + qty - 1 - i).toUpperCase()
          }

          front = model.models[item.uid].models[i] = new Front(
            item,
            label,
            this.fontFile,
            this.quote.region,
            materialHasGrain,
            i
          )

          makerjs.model.move(front, [0, innerY])
          innerY += front.height + this.frontSpacing
        }

        // move item into position
        if (previousItem === null || previousFront === undefined) {
          previousItem = item
          previousFront = front
        } else if (item.itemNum.toString() === previousItem.itemNum.toString()) {
          if (previousItem.type == 'lhd' && item.type == 'rhd') {
            xPos += previousFront.width + this.frontSpacing
          } else {
            yPos += (previousFront.height + this.frontSpacing) * previousItem.qty
          }
        } else if (
          item.itemNum.toString().includes('J') &&
          previousItem.itemNum.toString().includes('J') &&
          item.itemNum.toString().split('J')[0] === previousItem.itemNum.toString().split('J')[0]
        ) {
          yPos += (previousFront.height + this.frontSpacing) * previousItem.qty
          // if (
          //   item.handle.value == 'j' &&
          //   item.handle.orientation == 'h' &&
          //   item.cabinet == 'w' &&
          //   item.joiningPos == 'top'
          // ) {
          //   yPos += this.frontSpacing
          // }
        } else {
          let dist
          if (previousItem.special == '2part') {
            let ukTwoPartCnrDoorSpacing
            previousItem.w === 250 || previousFront.front.w === 250
              ? (ukTwoPartCnrDoorSpacing = 521)
              : (ukTwoPartCnrDoorSpacing = previousItem.w * 2 + 13)
            dist = this.quote.region === 'us' ? 687 + this.frontSpacing : ukTwoPartCnrDoorSpacing + this.frontSpacing
          } else if (previousItem.special == 'cnr') {
            // dist is front + narrow filler + wide filler + (2 x tool spacing of 13)
            let cornerDoorTotalSpace
            if (previousItem.cabinet == 'w') {
              cornerDoorTotalSpace = this.quote.region === 'uk' ? 220 : 229
            } else {
              cornerDoorTotalSpace = this.quote.region === 'uk' ? 140 : 121
            }
            dist = previousItem.w + cornerDoorTotalSpace + this.frontSpacing * 3
          } else if (previousItem.special == 'int') {
            dist =
              this.quote.region === 'us'
                ? previousFront.width + 44.625 + this.frontSpacing
                : previousFront.width + 37 + this.frontSpacing
          } else {
            dist = previousFront.width + this.frontSpacing
          }

          xPos += dist
          yPos = 0
        }

        makerjs.model.move(model.models[item.uid], [xPos, yPos])
        previousItem = item
        previousFront = front
      }

      if (materialSided == 1) {
        // add a mega warning for single sided material
        let text = new makerjs.models.Text(this.fontFile, 'Single Sided FRONT Material', 200)
        model.models.warningText = makerjs.model.mirror(text, true, false)
        model.models.warningText.layer = layerNames.warning.label

        const textMeasurements = makerjs.measure.modelExtents(model.models.warningText)
        makerjs.model.moveRelative(model.models.warningText, [
          -textMeasurements.center[0] + 2000,
          -textMeasurements.center[1] + 1000
        ])
      }
    },

    generateCoverPanels(items, model, materialHasGrain, materialSided) {
      let xPos = 0
      let maxY = 0
      for (let i = 0; i < items.length; i++) {
        let cp = items[i]
        for (let q = 0; q < cp.qty; q++) {
          if (cp.h == 75 && cp.type == 'plinth' && this.quote.region == 'uk' && materialHasGrain) {
            // dont make plinths for uk veneer
            continue
          } else {
            model.models[i + '-' + q] = new CoverPanel(cp, this.fontFile, this.quote.region)
            makerjs.model.move(model.models[i + '-' + q], [xPos, 0])
            if (['v-plinth', 'plinth', 'top', 'underside', 'shelf'].includes(cp.type)) {
              xPos += cp.h + this.frontSpacing
              if (cp.w > maxY) maxY = cp.w
            } else {
              xPos += cp.w + this.frontSpacing
              if (cp.h > maxY) maxY = cp.h
            }
          }
        }
      }

      makerjs.model.move(model, [0, -(maxY + this.frontSpacing + this.verticalItemTypeSpacing)])

      if (materialSided == 1) {
        // add a mega warning for single sided material
        let text = new makerjs.models.Text(this.fontFile, 'Single Sided CP Material', 200)
        model.models.warningText = makerjs.model.mirror(text, true, false)
        model.models.warningText.layer = layerNames.warning.label

        const textMeasurements = makerjs.measure.modelExtents(model.models.warningText)
        makerjs.model.moveRelative(model.models.warningText, [
          -textMeasurements.center[0] + 2000,
          -textMeasurements.center[1] + 1000
        ])
      }
    },

    generateUrtils(urtils, model, material) {
      let yPos = 0

      if (urtils) {
        urtils.forEach(urtil => {
          let label = urtil.name.toString()
          model.models[urtil.uid] = new Urtil(
            urtil,
            parseFloat(this.materialLookup[material].thickness),
            material,
            parseFloat(this.materialLookup[urtil.carcass.id].thickness),
            parseFloat(this.materialLookup[urtil.back.id].thickness),
            label,
            this.fontFile
          )
          makerjs.model.move(model.models[urtil.uid], [10000, yPos + 5000])
          yPos += urtil.d * 2 + 200
        })
      }
    },

    generateJHandles(handles) {
      let model = { models: {} }
      let material
      // create handle grouping
      let handleGroup = {}
      for (const handle of handles) {
        const qty = handle.special == 'cnr' ? 1 : handle.qty
        for (let i = 0; i < qty; i++) {
          let handleLength // length for handles

          material = handle.handle.insertMaterial.id
          let handleType = handle.handle.value
          let handleOrientation = handle.handle.orientation
          let handleCabinet = handle.cabinet

          if (handle.special === '2part') {
            handleLength = handleOrientation == 'v' ? handle.h + 4 : handle.w + 4
          } else if (['custom', 'paxsplit', 'pax', 'custom', 'int'].includes(handle.special)) {
            handleLength = handleOrientation == 'v' ? handle.h : handle.w
          } else {
            handleLength = handleOrientation == 'v' ? handle.h - 3 : handle.w - 3
          }
          // marking j-Bracket or j-Handles (inc. vert) handles
          let handleGroupName
          if (handleType == 'j') {
            if (
              // wall-cabinet will produce extra handles (in case of top-w joined to top-b)
              // jBracket only for bot and mid units.
              (handleCabinet == 'w' && handleOrientation == 'h' && handle.joiningPos == 'top') ||
              (handleOrientation == 'h' && ['mid', 'bot'].includes(handle.joiningPos))
            ) {
              handle.joiningDouble
                ? (handleGroupName = handleLength.toString() + '-JD')
                : (handleGroupName = handleLength.toString() + '-J')
            } else if (handleOrientation == 'v') {
              // jBracket cant be vert.
              handleGroupName = handleLength.toString() + '-v'
            } else {
              // everything else is a normal handle.
              handleGroupName = handleLength.toString()
            }
          } else {
            handleGroupName = null
          }

          // organise in object with key showing length of handle + type and value showing just length of handle.
          let handleGroupKeys = Object.keys(handleGroup)
          if (handleGroupKeys.includes(handleGroupName)) {
            handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
          } else {
            if (handleGroupName) {
              handleGroup[handleGroupName] = []
              handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
            }
          }
        }
      }

      let yPos = 0
      let xPos = 0
      let verticalShiftPos = 0

      let handle
      let jHandleHeight
      let isVertical

      const handleGroupKeys = Object.keys(handleGroup)
      for (const groupName of handleGroupKeys) {
        model.models[groupName + '_jHandles'] = { models: {} }

        let innerY = 0
        let widthQty = handleGroup[groupName].length

        // can't be jHandle vertical and joined, hence checking vertical first.
        if (groupName.split('-').includes('v')) {
          isVertical = true
        }

        if (groupName.split('-').includes('JD')) {
          jHandleHeight = 108
        } else if (groupName.split('-').includes('J')) {
          jHandleHeight = 82
        } else {
          jHandleHeight = 52.5
        }

        for (let i = 0; i < widthQty; i++) {
          handle = model.models[groupName + '_jHandles'].models[i] = new JHandle(
            handleGroup[groupName][i],
            jHandleHeight,
            this.quote.region
          )

          // pair and position same width handles
          if (i % 2 == 0) {
            let handlePair
            // mirror and re-pos
            handlePair = model.models[groupName + '_jHandles'].models[i] = makerjs.model.mirror(handle, false, true)
            // pos in index order
            makerjs.model.moveRelative(handlePair, [0, handle.handleHeight + innerY])
          } else {
            makerjs.model.move(handle, [0, innerY])
          }
          innerY += handle.handleHeight + this.handleSpacing
        }

        let dist
        dist = parseFloat(handleGroup[groupName][0]) + this.handleSpacing
        makerjs.model.move(model.models[groupName + '_jHandles'], [xPos, yPos])
        xPos += dist

        if (isVertical) {
          //rotate 90 if vertical handles
          makerjs.model.move(model.models[groupName + '_jHandles'], [0, jHandleHeight + this.handleSpacing])
          makerjs.model.rotate(model.models[groupName + '_jHandles'], 90)

          //shift left for vertical handles
          makerjs.model.move(model.models[groupName + '_jHandles'], [verticalShiftPos, 0])
          verticalShiftPos -= (jHandleHeight + this.handleSpacing) * handleGroup[groupName].length

          isVertical = false
        }

        this.models.push({
          name: `4mm-${this.materialLookup[material].name}-jHandles.dxf`,
          data: model
        })

        this.svgs[material + '-jHand'] = makerjs.exporter.toSVG(model)
      }
    },

    generateSRGHandles(handles) {
      let model = { models: {} }
      let material
      // create handle grouping
      let handleGroup = {}
      for (const handle of handles) {
        const qty = handle.special == 'cnr' ? 1 : handle.qty
        for (let i = 0; i < qty; i++) {
          let handleLength // length for handles

          let handleGrain = handle.handle.insertMaterial.hasGrain
          material = handle.handle.insertMaterial.id
          let handleType = handle.handle.value
          let handleOrientation = handle.handle.orientation

          switch (handleType) {
            case 'semiGrab':
              handleLength = 140
              break
            case 'midSemiGrab':
              handleLength = 200
              break
            case 'lrgSemiGrab':
              handleLength = 400
              break
          }

          // marking j-Bracket or j-Handles (inc. vert) handles or joined upside-down handles
          let handleGroupName
          if (
            (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handleType) && !handleGrain) ||
            (['midSemiGrab', 'lrgSemiGrab'].includes(handleType) && handleGrain) ||
            (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(handleType) && handle.joiningDouble)
          ) {
            if (handle.joiningDouble) {
              handleGroupName = handleLength.toString() + '-d'
            } else if (handleOrientation == 'v') {
              // joined SRG can't be vertical.
              handleGroupName = handleLength.toString() + '-v'
            } else {
              // everything else is a normal handle.
              handleGroupName = handleLength.toString()
            }
          } else {
            handleGroupName = null
          }

          // organise in object with key showing length of handle + type and value showing just length of handle.
          let handleGroupKeys = Object.keys(handleGroup)
          if (handleGroupKeys.includes(handleGroupName)) {
            handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
          } else {
            if (handleGroupName) {
              handleGroup[handleGroupName] = []
              handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
            }
          }
        }
      }

      let yPos = 0
      let xPos = 0

      let handle
      let srgHeight
      let isVertical

      const handleGroupKeys = Object.keys(handleGroup)
      for (const groupName of handleGroupKeys) {
        model.models[groupName + '_SRGHandles'] = { models: {} }

        let innerY = 0
        let widthQty = handleGroup[groupName].length

        // SRG can't be vertical and joined, hence checking vertical first.
        if (groupName.split('-').includes('v')) {
          isVertical = true
        }

        if (groupName.split('-').includes('d')) {
          srgHeight = 101
        } else {
          srgHeight = 49
        }

        for (let i = 0; i < widthQty; i++) {
          handle = model.models[groupName + '_SRGHandles'].models[i] = new SRGHandle(
            handleGroup[groupName][i],
            srgHeight
          )

          // pair and position same width handles
          if (i % 2 == 0) {
            let handlePair
            // mirror and re-pos
            handlePair = model.models[groupName + '_SRGHandles'].models[i] = makerjs.model.mirror(handle, false, true)
            // pos in index order
            makerjs.model.moveRelative(handlePair, [0, handle.handleHeight + innerY])
          } else {
            makerjs.model.move(handle, [0, innerY])
          }
          innerY += handle.handleHeight + this.handleSpacing
        }

        let dist
        dist = parseFloat(handleGroup[groupName][0]) + 38 + this.handleSpacing
        makerjs.model.move(model.models[groupName + '_SRGHandles'], [xPos, yPos])
        xPos += dist

        if (isVertical) {
          //rotate 90 if vertical handles
          makerjs.model.move(model.models[groupName + '_SRGHandles'], [0, srgHeight + this.handleSpacing])
          makerjs.model.rotate(model.models[groupName + '_SRGHandles'], 90)
          isVertical = false
        }

        this.models.push({
          name: `4mm-${this.materialLookup[material].name}-srg-handles.dxf`,
          data: model
        })

        this.svgs[material + '-srg'] = makerjs.exporter.toSVG(model)
      }
    },

    generateSRCHandles(handles, material) {
      let model = { models: {} }
      let handleCount = 0
      let yPos = 0
      let xPos = 0
      let handleRadius = 37
      let src = (model.models[material + '-SRCHandles'] = { models: {} })

      for (const handle of handles) {
        if (handleCount % 5 == 0) {
          yPos += handleRadius * 2 + this.handleSpacing
          xPos = 0
        }
        const qty = handle.special == 'cnr' ? 1 : handle.qty
        for (let i = 0; i < qty; i++) {
          let currSRC = (src.models[handle.uid + '-' + i] = new SRCHandle(handleRadius))

          let dist
          dist = handleRadius * 2 + this.handleSpacing
          makerjs.model.move(currSRC, [xPos, yPos])
          xPos += dist
          handleCount++
        }
      }

      this.models.push({
        name: `4mm-${this.materialLookup[material].name}-src-handles.dxf`,
        data: model
      })

      this.svgs[material + '-src'] = makerjs.exporter.toSVG(model)
    },

    generateHalfQuartArc(handles, material) {
      // create handle grouping
      let handleGroup = {}
      let handleLength

      for (const handle of handles) {
        let handleType = handle.handle.value
        const qty = handle.special == 'cnr' ? 1 : handle.qty
        for (let i = 0; i < qty; i++) {
          // marking halfArc vs quarterArc handles
          switch (handleType) {
            case 'lrgHalfArc':
              handleLength = 105
              break
            case 'halfArc':
              handleLength = 90
              break
            case 'lrgQuartArc':
              handleLength = 52.5
              break
            case 'quartArc':
              handleLength = 45
              break
          }

          let handleGroupName = handleLength.toString() + '-' + handleType

          // organise in object with key showing length of handle + type and value showing just length of handle in array.
          let handleGroupKeys = Object.keys(handleGroup)
          if (handleGroupKeys.includes(handleGroupName)) {
            handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
          } else {
            if (handleGroupName) {
              handleGroup[handleGroupName] = []
              handleGroup[handleGroupName].push(handleGroupName.split('-')[0])
            }
          }
        }
      }

      let model = { models: {} }
      const pocketSpacing = 19
      let yPos = 0
      let xPos = 0

      const handleGroupKeys = Object.keys(handleGroup)
      for (const groupName of handleGroupKeys) {
        let handleCount = 0
        let hqa = (model.models[material + groupName] = { models: {} })

        let widthQty = handleGroup[groupName].length

        for (let i = 0; i < widthQty; i++) {
          let currHQA = (hqa.models[i] = new HalfQuartArcHandle(groupName.split('-')[0], groupName.split('-')[1]))

          if (handleCount % 5 == 0) {
            yPos += ['lrgHalfArc', 'halfArc'].includes(groupName.split('-')[1])
              ? 52.5 + pocketSpacing + this.handleSpacing
              : 45 + pocketSpacing + this.handleSpacing
            xPos = 0
          }

          let dist
          switch (groupName.split('-')[1]) {
            case 'lrgHalfArc':
              dist = 105 + pocketSpacing * 2 + this.handleSpacing
              break
            case 'halfArc':
              dist = 90 + pocketSpacing * 2 + this.handleSpacing
              break
            case 'lrgQuartArc':
              dist = 52.5 + pocketSpacing + this.handleSpacing
              break
            case 'quartArc':
              dist = 45 + pocketSpacing + this.handleSpacing
              break
          }

          makerjs.model.move(currHQA, [xPos, yPos])
          xPos += dist
          handleCount++
        }
        yPos += 100 // spacing between handle types
      }

      this.models.push({
        name: `4mm-${this.materialLookup[material].name}-half-quarter-arc-handles.dxf`,
        data: model
      })
      this.svgs[material + '-hqa'] = makerjs.exporter.toSVG(model)
    },

    generatePantries(pantries, model, material) {
      let yPos = 0

      if (pantries) {
        pantries.forEach(pantry => {
          let label = pantry.name.toString()

          if (pantry.base) {
            model.models[pantry.uid] = new Pantry(
              pantry,
              parseFloat(this.materialLookup[material].thickness),
              material,
              parseFloat(this.materialLookup[pantry.carcass.id].thickness),
              parseFloat(this.materialLookup[pantry.base.id].thickness),
              label,
              this.fontFile
            )
          } else {
            model.models[pantry.uid] = new Pantry(
              pantry,
              parseFloat(this.materialLookup[material].thickness),
              material,
              parseFloat(this.materialLookup[pantry.carcass.id].thickness),
              null, // no base
              label,
              this.fontFile
            )
          }
          makerjs.model.move(model.models[pantry.uid], [-3000, yPos])
          yPos += pantry.d * 4 + 200
        })
      }
    },

    dxf() {
      this.dxfDialog = false

      let zip = new jszip()
      zip.folder(this.customerEmail)

      for (let i = 0; i < this.models.length; i++) {
        this.models[i]
        let data = makerjs.exporter.toDXF(
          this.models[i].data,
          // colors - https://github.com/Microsoft/maker.js/blob/b393c682/packages/maker.js/src/core/exporter.ts#L68
          // TODO ensure all layer names confirm to [TOOL] [OPERATION] @ [depth]
          layerOptions
        )
        let turboCadString = data.replace(/MTEXT/g, 'TEXT')
        let blob = new Blob([turboCadString], { type: 'text/dxf' })
        // let blob = new Blob([data], { type: 'text/dxf' })
        let filename =
          this.models[i].name ||
          i +
            '_' +
            this.materialLookup[this.models[i].materialID].thickness +
            'mm-' +
            this.materialLookup[this.models[i].materialID].name +
            '.dxf'
        let chickenShwarma = filename
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
        zip.file(this.customerEmail + '/' + chickenShwarma, blob)
      }

      // add notes if they exist
      if (this.version.notes) {
        let notes = new Blob([this.version.notes])
        zip.file(this.customerEmail + '/notes_README.txt', notes)
      }

      zip.generateAsync({ type: 'blob' }).then(function(content) {
        filesaver.saveAs(content, 'MegaCad')
      })
    }
  }
}
</script>
<style lang="scss">
.material {
  margin: $spacer * 4;
  .svg {
    width: 100%;
    height: 400px;
    overflow: hidden;
    svg {
      width: inherit;
      height: inherit;
    }
  }
}
</style>
