var makerjs = require('makerjs')

import { layerNames } from '@/js/cad/CadLayerNaming.js'

import GrabHandle from './handles/GrabHandle'
import SemiRecessedGrab from './handles/SemiRecessedGrab'
import HalfQuartArc from './handles/HalfQuartArc'
import CirclePull from './handles/CirclePull'
import SemiRecessedCircle from './handles/SemiRecessedCircle'
import EdgePullRear from './handles/EdgePullRear'
import JProfile from './handles/JProfile'
import Invisipull from './handles/Invisipull'
import DPull from './handles/DPull'
import JBracket from './handles/JBracket'

import Hinges from '@/js/cad/production/Hinges'
import HingesUs from '@/js/cad/production/HingesUs'
import MetalJoiningBracket from '@/js/cad/production/MetalJoiningBracket'

import DrawerHoles from '@/js/cad/production/DrawerHoles'
import DrawerHolesUs from '@/js/cad/production/DrawerHolesUs'
import PlatsaDrawerFixings from '@/js/cad/production/specials/PlatsaDrawerFixings'

import TwoPartCornerDoor from '@/js/cad/production/specials/TwoPartCornerDoor'
import TwoPartCornerDoorUs from '@/js/cad/production/specials/TwoPartCornerDoorUs'
import CornerFrontDoor from '@/js/cad/production/specials/CornerFrontDoor'
// import Renlig from '@/js/cad/production/specials/Renlig'

export default class Front {
  // TODO add the handle orientation to the front type eg lhd-w-c / lhd-b-h / lhd-b-v
  // (hinge) - (cabinet) - (handle orientation)

  // GOTCHAS
  // center grab and semi-recessed on the 200mm wide door / drw will be centered for pull out but could be LHD
  // Chec what happens on placement on 2pst corner door

  constructor(front, label, font, region, hasGrain, count) {
    this.label = label
    this.font = font
    this.region = region
    this.hasGrain = hasGrain
    this.trimAmount = 3
    this.frontCornerRadius = 2
    this.labelInset = 15
    this.fontSize = 10
    this.count = count

    this.invisipullAdjust = 30
    this.jProfileAdjust = 26
    this.toolSpacing = region == 'uk' ? 13 : 11

    this.models = {}
    this.front = front

    this.width = ['custom', 'int', 'pax', 'paxsplit'].includes(front.special) ? front.w : front.w - this.trimAmount

    this.height = ['custom', 'int', 'pax', 'paxsplit'].includes(front.special) ? front.h : front.h - this.trimAmount

    this.addCabinetLabel()
    this.front.joiningDouble ? this.addDoubleHandleWarning(this.front.handle.value) : null

    switch (this.front.special) {
      case '2part':
        this.models.twoPartFront =
          this.region === 'us'
            ? new TwoPartCornerDoorUs(this.front, this.frontCornerRadius, this.region)
            : new TwoPartCornerDoor(this.front, this.frontCornerRadius)
        break
      case 'cnr':
        this.models.cornerFrontWithFiller = new CornerFrontDoor(this.front, this.font, this.region)
        break
      // case 'renlig':
      //   this.models.renlig = new Renlig(this.front, this.frontCornerRadius)
      //   break
      default:
        this.regularFront()
    }
  }

  regularFront() {
    let handleType = this.front.handle.value

    // make any size adjustments
    let doorHeight = this.height
    let doorWidth = this.width

    if (handleType == 'invisipull') {
      doorHeight += this.invisipullAdjust
    } else if (handleType == 'j' && this.front.handle.orientation == 'h') {
      doorHeight -= this.jProfileAdjust
    } else if (handleType == 'j' && this.front.handle.orientation == 'v') {
      doorWidth -= this.jProfileAdjust
    }

    if (handleType == 'j') {
      this.models.outline = {
        paths: {
          line1: {
            type: 'line',
            origin: [0, 0],
            end: [doorWidth, 0]
          },
          line2: {
            type: 'line',
            origin: [doorWidth, 0],
            end: [doorWidth, doorHeight]
          },
          line3: {
            type: 'line',
            origin: [doorWidth, doorHeight],
            end: [0, doorHeight]
          },
          line4: {
            type: 'line',
            origin: [0, doorHeight],
            end: [0, 0]
          }
        }
      }

      if (this.front.handle.orientation == 'h') {
        if (this.front.cabinet == 'w') {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line3,
            this.models.outline.paths.line2,
            2
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line3,
            this.models.outline.paths.line4,
            2
          ) //
        } else {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line1,
            this.models.outline.paths.line2,
            2
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line1,
            this.models.outline.paths.line4,
            2
          )
        }
      }
      if (this.front.handle.orientation == 'v') {
        if (this.front.type === 'rhd') {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line2,
            this.models.outline.paths.line1,
            2
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line2,
            this.models.outline.paths.line3,
            2
          )
        } else {
          this.models.outline.paths.arc1 = makerjs.path.fillet(
            this.models.outline.paths.line4,
            this.models.outline.paths.line1,
            2
          )
          this.models.outline.paths.arc2 = makerjs.path.fillet(
            this.models.outline.paths.line4,
            this.models.outline.paths.line3,
            2
          )
        }
      }
    } else {
      this.models.outline = new makerjs.models.RoundRectangle(doorWidth, doorHeight, this.frontCornerRadius)
    }

    this.models.outline.layer = layerNames.cutout.label

    // add the handle
    // catch large pulls on small doors
    if (this.width < 245 && this.front.handle.value == 'edgeLarge') {
      handleType = 'edgeReg'
    }

    let handleWidth
    switch (handleType) {
      case 'semiGrab':
        handleWidth = 140
        break
      case 'midSemiGrab':
        handleWidth = 200
        break
      case 'lrgSemiGrab':
        handleWidth = 400
        break
      case 'lrgHalfArc':
        handleWidth = 105
        break
      case 'halfArc':
        handleWidth = 90
        break
      case 'lrgQuartArc':
        handleWidth = 52.5
        break
      case 'quartArc':
        handleWidth = 45
        break
    }

    let skipHandle = false

    switch (handleType) {
      case 'none':
        skipHandle = true
        break
      case 'grab':
        this.models.handle = new GrabHandle(this.models, this.region)
        break
      case 'semiGrab':
      case 'midSemiGrab':
      case 'lrgSemiGrab':
        this.models.handle = new SemiRecessedGrab(this.models, handleWidth, this.region)
        if (!this.hasGrain) {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
          this.models.boundingbox.layer = layerNames.boundingBox.label
        }
        break
      case 'lrgHalfArc':
      case 'halfArc':
      case 'lrgQuartArc':
      case 'quartArc':
        this.models.handle = new HalfQuartArc(this.models, handleType, handleWidth, this.region)
        break
      case 'circle':
        this.models.handle = new CirclePull(this.models)
        break
      case 'semiCircle':
      case 'semiCir':
        this.models.handle = new SemiRecessedCircle(this.models)
        break
      case 'edge-br-s':
      case 'edge-bl-s':
      case 'edge-w-s':
      case 'edge-sn-s':
      case 'edge-cp-s':
      case 'edge-br-s-r':
      case 'edge-bl-s-r':
      case 'edge-w-s-r':
      case 'edge-sn-s-r':
      case 'edge-cp-s-r':
        this.models.handle = new EdgePullRear(this.models, 76, doorWidth, doorHeight)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'edge-br-l':
      case 'edge-bl-l':
      case 'edge-w-l':
      case 'edge-sn-l':
      case 'edge-cp-l':
      case 'edge-br-l-r':
      case 'edge-bl-l-r':
      case 'edge-w-l-r':
      case 'edge-sn-l-r':
      case 'edge-cp-l-r':
        this.models.handle = new EdgePullRear(this.models, 201, doorWidth, doorHeight)
        // bounding box to fix nesting craziness
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth, doorHeight)
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'j': {
        let size
        this.front.handle.orientation == 'v' ? (size = this.height) : (size = this.width)
        this.models.handle = new JProfile(this.models, size)
        if (this.front.handle.orientation == 'v' && this.front.type == 'rhd') {
          makerjs.model.move(this.models.outline, [26, 0])
        }
        if (this.front.handle.orientation == 'h' && this.front.cabinet == 'w') {
          makerjs.model.move(this.models.outline, [0, 26])
        }

        if (this.hasGrain && this.front.handle.orientation == 'v') {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2 + this.jProfileAdjust, doorHeight + 2)
        } else if (this.hasGrain && this.front.handle.orientation != 'v') {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2 + this.jProfileAdjust)
        } else if (!this.hasGrain) {
          this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2 + this.jProfileAdjust)
          makerjs.model.move(this.models.boundingbox, [0, this.jProfileAdjust])
        }
        makerjs.model.move(this.models.boundingbox, [-1, -1])
        this.models.boundingbox.layer = layerNames.boundingBox.label

        break
      }
      case 'invisipull':
        this.models.handle = new Invisipull(this.models, this.width)
        makerjs.model.move(this.models.outline, [0, -30])
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2)
        makerjs.model.move(this.models.boundingbox, [-1, -31])
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'invisipull-short':
        this.models.handle = new Invisipull(this.models, this.width, true)
        this.models.boundingbox = new makerjs.models.Rectangle(doorWidth + 2, doorHeight + 2)
        makerjs.model.move(this.models.boundingbox, [-1, -1])
        this.models.boundingbox.layer = layerNames.boundingBox.label
        break
      case 'dpull-br-s':
      case 'dpull-bl-s':
      case 'dpull-w-s':
        this.models.handle = new DPull(this.models, 128, doorWidth, doorHeight)
        break
      case 'dpull-br-l':
      case 'dpull-bl-l':
      case 'dpull-w-l':
        this.models.handle = new DPull(this.models, 192, doorWidth, doorHeight)
        break
      default:
        skipHandle = true
        console.log('handle type not implemented yet', handleType)
    }

    if (!skipHandle) {
      // adjust handle position

      // if (!this.models.handle.fixedRotation) this.rotateHandle()
      this.rotateHandle()
      this.moveHandle()

      // subtract any handle outline
      if (this.models.handleOutline) {
        makerjs.model.combineSubtraction(this.models.outline, this.models.handleOutline)
      }
    }

    if (
      !(
        // if not the following:
        (
          this.front.special === 'custom' ||
          this.front.type === 'blank' ||
          // joined+top drw door with w==197||297 needs to be empty hinge.
          (this.front.joiningPos === 'top' && this.front.type === 'drw' && (this.width === 197 || this.width === 297))
        )
      )
    ) {
      switch (this.front.type) {
        case 'drw':
          if (this.front.special === 'platsa') {
            this.models.drw = new PlatsaDrawerFixings(this.width, this.height)
          } else {
            this.models.drw =
              this.region === 'us'
                ? new DrawerHolesUs(this.width, this.height, this.front)
                : new DrawerHoles(this.width, this.height, this.front)
          }
          break
        default:
          this.models.hinges =
            this.region === 'us'
              ? new HingesUs(this.width, this.height, this.front)
              : new Hinges(this.width, this.height, this.front, this.count)
      }
    }

    // dont need to add pocket rebate for j-bracket on bottom doors (already has regular j-handle)
    if (
      ['invisipull-short', 'j'].includes(handleType) &&
      this.front.handle.orientation == 'h' &&
      this.front.cabinet == 'w'
    ) {
      if (['mid', 'bot'].includes(this.front.joiningPos)) {
        // wall unit with joiningPos 'top' will not need J-Bracket because there's already the handle pocket
        this.addJBracketPockets(doorWidth, doorHeight, 'top') // wall unit with bracket on top edge
      }
    } else if (['j'].includes(handleType) && this.front.handle.orientation == 'h' && this.front.cabinet != 'w') {
      // drw, custom, base units
      if (['top', 'mid'].includes(this.front.joiningPos)) {
        // bot joining position already has bracket from normal handle
        this.addJBracketPockets(doorWidth, doorHeight, 'bot') // unit with bracket on bot edge
      }
    } else if (
      ['invisipull-short'].includes(handleType) &&
      this.front.handle.orientation == 'h' &&
      this.front.cabinet != 'w'
    ) {
      // drw, custom, base units && invisipull-short // QT should be picking J-Profile (but catching it anyways)
      if (['top', 'mid', 'bot'].includes(this.front.joiningPos)) {
        // bot joining position already has bracket from normal handle
        this.addJBracketPockets(doorWidth, doorHeight, 'top') // unit with bracket on bot edge
      }
    } else if (handleType != ('j' || 'invisipull-short') || this.front.handle.orientation == 'v') {
      // pocket rebate for metal joining bracket
      if (
        ['top', 'mid', 'bot'].includes(this.front.joiningPos) &&
        !(handleType == 'lrgSemiGrab' && this.front.w <= 647)
      ) {
        this.addMetalBracketPockets()
      }
    }
  }

  addMetalBracketPockets() {
    if (this.count == 0) {
      const bracketWidth = 17
      const bracketHeight = 42.5
      const bracketRadius = 8.5
      const bracketOverlap = 5

      let bracketInset
      if (this.front.handle.value == 'lrgSemiGrab') {
        bracketInset = 40
      } else if (this.width >= 297) {
        bracketInset = 70
      } else {
        bracketInset = 50
      }

      // semi-recessed grab inset
      let rhdSrgInset = 0
      let lhdSrgInset = 0
      let srgInsetValue = 0
      switch (this.front.handle.value) {
        case 'semiGrab':
          srgInsetValue = 140 + 40
          break
        case 'midSemiGrab':
          srgInsetValue = 200 + 40
          break
        case 'lrgSemiGrab':
          srgInsetValue = 400 + 40
          break
        default:
          srgInsetValue = 100
          break
      }

      if (['semiGrab', 'midSemiGrab', 'lrgSemiGrab'].includes(this.front.handle.value)) {
        if (this.front.handle.orientation == 'h' && this.front.type == 'rhd') {
          rhdSrgInset = srgInsetValue
        } else if (this.front.handle.orientation == 'h' && this.front.type == 'lhd') {
          lhdSrgInset = srgInsetValue
        }
      }

      this.models.bracketA = new MetalJoiningBracket(bracketWidth, bracketHeight, bracketRadius)
      this.models.bracketB = new MetalJoiningBracket(bracketWidth, bracketHeight, bracketRadius)

      switch (this.front.joiningPos) {
        case 'top':
          // bracket on the bottom edge, dome faced up
          makerjs.model.move(this.models.bracketA, [bracketInset + bracketRadius + rhdSrgInset, -bracketOverlap])
          makerjs.model.move(this.models.bracketB, [
            this.width - bracketInset - bracketRadius - lhdSrgInset,
            -bracketOverlap
          ])
          break
        case 'mid':
          // bracket on the bottom edge, dome faced up
          makerjs.model.move(this.models.bracketA, [bracketInset + bracketRadius + rhdSrgInset, -bracketOverlap])
          makerjs.model.move(this.models.bracketB, [
            this.width - bracketInset - bracketRadius - lhdSrgInset,
            -bracketOverlap
          ])

          // bracket on the top edge, dome faced down
          this.models.bracketC = makerjs.model.mirror(this.models.bracketA, false, true)
          this.models.bracketD = makerjs.model.mirror(this.models.bracketB, false, true)
          makerjs.model.move(this.models.bracketC, [
            bracketInset + bracketRadius + rhdSrgInset,
            this.height + bracketOverlap
          ])
          makerjs.model.move(this.models.bracketD, [
            this.width - bracketInset - bracketRadius - lhdSrgInset,
            this.height + bracketOverlap
          ])
          break
        case 'bot':
          // bracket on the top edge, dome faced down
          this.models.bracketC = makerjs.model.mirror(this.models.bracketA, false, true)
          this.models.bracketD = makerjs.model.mirror(this.models.bracketB, false, true)
          makerjs.model.move(this.models.bracketC, [
            bracketInset + bracketRadius + rhdSrgInset,
            this.height + bracketOverlap
          ])
          makerjs.model.move(this.models.bracketD, [
            this.width - bracketInset - bracketRadius - lhdSrgInset,
            this.height + bracketOverlap
          ])
          //remove reference brackets
          delete this.models.bracketA
          delete this.models.bracketB
          break
      }
    }
  }

  addJBracketPockets(doorWidth, doorHeight, bracketPos) {
    // pocket rebate for j-bracket (j-handle bracket)
    let jBracketInsetHeight = 26
    let invisipullOffset = 30
    let jBracketOffset = 5
    let hingeBumpDist = this.region == 'uk' ? 50 : 32
    if (this.count == 0) {
      this.models.bracket = new JBracket(doorWidth)
      switch (bracketPos) {
        case 'bot':
          makerjs.model.move(this.models.bracket, [0, jBracketInsetHeight - jBracketOffset])
          break
        case 'top':
          if (this.front.handle.value === 'invisipull-short') {
            makerjs.model.move(this.models.bracket, [
              0,
              doorHeight - invisipullOffset + jBracketInsetHeight + jBracketOffset
            ])
          } else {
            makerjs.model.move(this.models.bracket, [0, doorHeight + jBracketInsetHeight])
          }
          break
      }
      // bump hinges and engraving
      if (['top', 'mid'].includes(this.front.joiningPos) && bracketPos == 'bot') {
        if ('hinges' in this.models) {
          makerjs.model.moveRelative(this.models.hinges.models.bottomHinge, [0, hingeBumpDist])
        }
        if (this.front.cabinet !== 'w') {
          makerjs.model.moveRelative(this.models.snazzyText, [0, 20])
        }
      } else if (['top', 'mid', 'bot'].includes(this.front.joiningPos) && bracketPos == 'top') {
        if (this.front.cabinet == 'w') {
          makerjs.model.moveRelative(this.models.snazzyText, [0, -hingeBumpDist])
        }
        if ('hinges' in this.models && this.front.special !== 'custom') {
          // ignore if custom and no hinges
          makerjs.model.moveRelative(this.models.hinges.models.topHinge, [0, -hingeBumpDist])
        }
      }
    }
  }

  addCabinetLabel() {
    let drawerInsetY = 20
    let doorInsetX = 23
    let doorInsetY = this.front.special && this.front.special !== '2part' ? 20 : 13
    let textCenter
    let yPos = this.front.cabinet == 'w' ? this.height - doorInsetY : doorInsetY
    let cornerDoorTotalSpace
    if (this.front.cabinet == 'w') {
      cornerDoorTotalSpace = this.region === 'uk' ? 220 : 229
    } else {
      cornerDoorTotalSpace = this.region === 'uk' ? 140 : 121
    }

    switch (this.front.type) {
      case 'drw':
      case 'bhd':
      case 'blank':
        textCenter = [this.width / 2, drawerInsetY]
        break
      case 'thd':
        textCenter = [this.width / 2, this.height - drawerInsetY]
        break
      case 'lhd':
        textCenter =
          this.front?.special == 'cnr'
            ? [cornerDoorTotalSpace + this.toolSpacing * 2 + doorInsetX, yPos]
            : [doorInsetX, yPos]

        break
      case 'rhd':
        textCenter =
          this.front?.special == '2part'
            ? [this.width * 2, yPos]
            : this.front?.special == 'cnr'
            ? [this.width + this.toolSpacing - doorInsetX, yPos]
            : [this.width - doorInsetX, yPos]
        break
      default:
        console.log('unknown type', this.front.type)
        break
    }

    if (this.front.handle.orientation == 'updn') textCenter = [doorInsetX, doorInsetY]

    var text = new makerjs.models.Text(this.font, this.label.toUpperCase(), this.fontSize)
    this.models.snazzyText = makerjs.model.mirror(text, true, false)
    this.models.snazzyText.layer = layerNames.engrave.label

    var textMeasurements = makerjs.measure.modelExtents(this.models.snazzyText)
    makerjs.model.moveRelative(this.models.snazzyText, [-textMeasurements?.center[0], -textMeasurements?.center[1]])
    makerjs.model.moveRelative(this.models.snazzyText, textCenter)
  }

  rotateHandle() {
    let hinge = this.front.type
    let cabinet = this.front.cabinet
    let position = this.front.handle.orientation
    let handleType = this.front.handle.value
    let rotation = 0

    // set the required rotation of the handle
    if (((position == 'h' || position == 'c') && cabinet == 'w') || position == 'updn') {
      rotation = 180
    }
    if ((position == 'h' || position == 'c') && cabinet == 'w') {
      if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
        hinge == 'lhd' ? (rotation = 270) : (rotation = 180)
      } else {
        rotation = 180
      }
    } else if (position.charAt(0) == 'v') {
      if (hinge == 'lhd') {
        rotation = 270
      } else if (hinge == 'rhd') {
        handleType == 'quartArc' || handleType == 'lrgQuartArc' ? (rotation = -90) : (rotation = 90)
      }
    } else if (
      position == 'h' &&
      cabinet == 'b' &&
      hinge == 'rhd' &&
      (handleType == 'quartArc' || handleType == 'lrgQuartArc')
    ) {
      rotation = 90
    }

    // rotate the handle and it's outline
    if (this.models.handleOutline) makerjs.model.rotate(this.models.handleOutline, rotation, [0, 0])
    makerjs.model.rotate(this.models.handle, rotation, [0, 0])
  }

  moveHandle() {
    let hinge = this.front.type
    let cabinet = this.front.cabinet
    let position = this.front.handle.orientation
    let handleType = this.front.handle.value
    let xPos = 0
    let yPos = 0

    // all VERTICAL handles
    if (position.charAt(0) == 'v' && hinge != 'drw') {
      xPos = hinge == 'lhd' ? this.width - this.models.handle.insetY : this.models.handle.insetY

      if (position == 'vc') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.height / 2
        } else {
          yPos =
            hinge == 'lhd'
              ? this.height / 2 + this.models.handle.width / 2
              : this.height / 2 - this.models.handle.width / 2
        }
      } else if (position == 'vbt') {
        yPos =
          hinge == 'lhd'
            ? 797 - this.models.handle.insetX
            : 797 - (this.models.handle.width + this.models.handle.insetX)
      } else if (position == 'vwb') {
        yPos =
          hinge == 'lhd' ? 800 + this.models.handle.width + this.models.handle.insetX : 800 + this.models.handle.insetX
      } else if (cabinet == 'b') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.height - this.models.handle.insetX - this.models.handle.width
        } else {
          yPos =
            hinge == 'lhd'
              ? this.height - this.models.handle.insetX
              : this.height - (this.models.handle.width + this.models.handle.insetX)
        }
      } else if (cabinet == 'w') {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          yPos = this.models.handle.insetX + this.models.handle.width
        } else {
          yPos = hinge == 'lhd' ? this.models.handle.width + this.models.handle.insetX : this.models.handle.insetX
        }
      }
    }

    // all HORIZONTAL handles
    // && !NOT drw, thd, bhd, blank, 197w grab/semigrab
    else if (
      position == 'h' &&
      hinge != 'drw' &&
      hinge != 'thd' &&
      hinge != 'bhd' &&
      !(this.width == 197 && (handleType == 'grab' || handleType == 'semiGrab'))
    ) {
      yPos = cabinet == 'b' ? this.height - this.models.handle.insetY : this.models.handle.insetY
      if (hinge == 'lhd') {
        // need to use switch case for large-halfArc and large-quartArc
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          xPos = this.width - this.models.handle.width - this.models.handle.insetX
          // yPos = cabinet == 'b' ? this.height - this.models.handle.insetY : this.models.handle.insetY
        } else if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          xPos = this.width + this.models.handle.shadowOffset + 0.025 // 0.025 overlap tolerance
        } else {
          xPos =
            cabinet == 'b'
              ? this.width - (this.models.handle.width + this.models.handle.insetX)
              : this.width - this.models.handle.insetX
        }
      } else {
        if (handleType == 'halfArc' || handleType == 'lrgHalfArc') {
          xPos = this.models.handle.width + this.models.handle.insetX
        } else if (handleType == 'quartArc' || handleType == 'lrgQuartArc') {
          xPos = -this.models.handle.shadowOffset - 0.025 // 0.025 overlap tolerance
        } else {
          xPos = cabinet == 'b' ? this.models.handle.insetX : this.models.handle.width + this.models.handle.insetX
        }
      }
    }

    // all CENTRAL handles
    // && drw, thd, bhd, blank, 197w grab/semigrab
    else if (
      position == 'c' ||
      hinge == 'drw' ||
      (this.width == 197 && (handleType == 'grab' || handleType == 'semiGrab')) ||
      hinge == 'thd' ||
      hinge == 'bhd' ||
      hinge == 'lhd' ||
      hinge == 'rhd' ||
      hinge == 'blank'
    ) {
      if ((cabinet == 'b' || hinge == 'drw' || hinge == 'blank') && position != 'updn') {
        yPos = this.height - this.models.handle.insetY
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? (xPos = this.width / 2)
          : (xPos = this.width / 2 - this.models.handle.width / 2)
      } else {
        yPos = this.models.handle.insetY
        handleType == 'halfArc' || handleType == 'lrgHalfArc'
          ? (xPos = this.width / 2)
          : (xPos = this.width / 2 + this.models.handle.width / 2)
      }
    } else {
      console.warn('Front move not caught')
    }

    if (this.models.handleOutline) makerjs.model.move(this.models.handleOutline, [xPos, yPos])
    makerjs.model.move(this.models.handle, [xPos, yPos])
  }

  addDoubleHandleWarning(handleType) {
    // add a mega warning [ONLY FOR DOUBLE JOINING HANDLE]
    let text
    if (handleType == 'j') {
      text = new makerjs.models.Text(this.font, 'Double J-Bracket', 40)
    } else {
      text = new makerjs.models.Text(this.font, 'Double SRG-Handle', 40)
    }
    this.models.warningText = makerjs.model.mirror(text, true, false)
    this.models.warningText.layer = layerNames.warning.label

    const textMeasurements = makerjs.measure.modelExtents(this.models.warningText)
    makerjs.model.moveRelative(this.models.warningText, [
      -textMeasurements.center[0] + this.front.w / 2,
      -textMeasurements.center[1] + this.front.h / 2
    ])
  }
}
